<template>
  <div class="row">
    <div class="col-xxl-12">
      <h3 class="mb-4">Dane podstawowe</h3>

      <div class="row">
        <div class="col-xl-3">
          <b-form-group label="Lek">
            <b-form-input v-model="card.drug" />
          </b-form-group>
        </div>

        <div class="col-xl-3">
          <b-form-group label="Seria">
            <b-form-input v-model="card.series" />
          </b-form-group>
        </div>

        <div class="col-xl-3">
          <b-form-group label="Data">
            <b-form-datepicker v-model="card.date" />
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="col-xxl-12">
      <h3 class="mb-6">Karta rozchodu leku</h3>

      <div class="row">
        <div class="col-xl-3">
          <b-form-group
              v-for="(field, index) in this.card.drug_card.fields"
              v-bind:key="'cardField' + index"
              :label="field.label"
          >
            <b-form-input v-model="field.value" />
          </b-form-group>
        </div>
      </div>

      <section class="dataTable">
        <div class="topHeader">
          <div class="tableRow">
            <div>Przyjęcie produktu leczniczego weterynaryjnego</div>
            <div>Zużycie produktu leczniczego weterynaryjnego</div>
          </div>
        </div>
        <header>
          <div class="tableRow">
            <div>lp.</div>
            <div>data zakupu</div>
            <div>nazwa i adres dostawcy</div>
            <div>liczba opakowań bezpośrednich</div>
            <div>zawartość opakowania bezpośredniego - ilość</div>
            <div>nr serii</div>
            <div>okres wazności</div>
            <div>data zużycia</div>
            <div>ilość</div>
            <div>numer pozycji w dokumentacji lekarsko weterynaryjnej</div>
          </div>
        </header>
        <section>
          <div
            class="tableRow"
            v-for="(row, index) in this.card.drug_card.rows"
            v-bind:key="'tableRow' + index"
          >
            <div v-for="(field, key) in row" v-bind:key="key + index">
              <b-form-input
                v-model="row[key]"
                v-if="!['buyDate', 'dateUsed'].includes(key)"
              />
              <b-form-datepicker
                v-model="row[key]"
                v-else
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </div>
            <a @click.prevent="addRow" class="rowBtn">+</a>
            <a @click.prevent="removeRow(index)" class="rowBtn" v-if="index > 0">-</a>
          </div>
        </section>
      </section>

      <b-button @click="saveItem" variant="primary" class="mt-3"
      >Zapisz</b-button
      >
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DrugCardForm",
  computed: {
    ...mapGetters(["drugCards"]),
  },
  mounted() {
    this.$store.dispatch("drugCardsList");
  },
  watch: {
    drugCards: {
      handler() {
        let items = this.drugCards.filter(
            (el) => el.id == this.$route.params.id
        );

        if (items.length > 0) {
          this.card.series = items[0].series;
          this.card.drug = items[0].drug;
          this.card.date = items[0].date.date;
          this.card.drug_card = items[0].drug_card;
        }
      },
      immediate: true,
    },
  },
  methods: {
    addRow() {
      this.card.drug_card.rows.push({ ...this.card.drug_card.rows[0] });
    },
    removeRow(index) {
      if (index === 0) return;

      this.card.drug_card.rows = [
        ...this.card.drug_card.rows.slice(0, index),
        ...this.card.drug_card.rows.slice(index + 1),
      ];
    },
    saveItem() {
      let payload = {
        ...this.card,
      };

      if (this.$route.params.id)
        this.$store.dispatch("drugCardEdit", {
          id: this.$route.params.id,
          ...payload,
        });
      else this.$store.dispatch("drugCardAdd", payload);

      this.$store.dispatch("drugCardsList");

      this.$router.push("/lab/drug-card");
    }
  },
  data() {
    return {
      card: {
        series: "",
        drug: "",
        date: "",
        drug_card: {
          fields: {
            name: {
              label: "Nazwa produktu leczniczego wteterynaryjnego",
              value: "",
            },
            cardNumber: {
              label: "Nr karty/rok",
              value: "",
            },
            responsiblePartyName: {
              label: "Nazwa podmioty odpowiedzialnego",
              value: "",
            },
            form: {
              label:
                "Postać farmaceutyczna produktu leczniczego weterynaryjnego",
              value: "",
            },
            packageType: {
              label: "Rodzaj opakowania produktu leczniczego weterynaryjnego",
              value: "",
            },
            period: {
              label: "Okres karencji produktu leczniczego weterynaryjnego",
              value: "",
            },
            usageCategory: {
              label:
                "Kategoria stosowania produktu leczniczego weterynaryjnego",
              value: "",
            },
            accessCategory: {
              label:
                "Kategoria dostępności produktu leczniczego weterynaryjnego",
              value: "",
            },
            prescription: {
              label: "Czy wystawiono receptę",
              value: "",
            },
            prescriptionPlace: {
              label: "Miejsce realizacji recepty",
              value: "",
            },
          },
          rows: [
            {
              lp: 1,
              buyDate: "",
              supplierName: "",
              packagesAmount: "",
              packageContentsAmount: "",
              series: "",
              expiryDate: "",
              dateUsed: "",
              amount: "",
              positionNo: "",
            },
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.rowBtn {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid #00aff0;
  color: #00aff0;
  text-align: center;
  line-height: 1;
  background: #fff;

  &:hover {
    color: #fff;
    background: #00aff0;
  }
}
.dataTable {
  width: 100%;
  margin: 20px 0;

  .topHeader {
    .tableRow {
      div {
        padding: 10px 0;
        text-align: center;

        &:first-of-type {
          grid-column: 1 / span 7;
        }
        &:last-of-type {
          grid-column: 8 / span 3;
        }
      }
    }
  }

  header {
    font-weight: bold;
    font-size: 14px;

    .tableRow {
      div {
        padding: 2px 15px;
      }
    }
  }

  .tableRow {
    display: grid;
    grid-template-columns: 60px repeat(6, 1fr) 150px 60px 1fr;
    position: relative;

    .rowBtn {
      position: absolute;
      right: -7px;
      top: 50%;
      transform: translateY(-20px);

      &:last-of-type {
        transform: none;
      }
    }

    div {
      border: 1px solid #1b1b28;
      border-bottom: none;
      border-right: none;

      &:last-of-type {
        border-right: 1px solid #1b1b28;
      }
    }

    &:last-of-type {
      div {
        border-bottom: 1px solid #1b1b28;
      }
    }
  }
}
</style>
